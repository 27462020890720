import React,{useState,useEffect,useRef} from "react";
import { Card } from "react-bootstrap";
import axios from 'axios'

const useLoad = () => {
    const [itemsL1,setItemsL1]=useState([])
    const [itemsL2,setItemsL2]=useState([])

    useEffect(function () {
        (async function() {
            const response = await axios.get("https://jsonplaceholder.typicode.com/todos?_limit=10"
            ).then((response) => {
                let arItemsL1=[];
                let arItemsL2=[];
                response.data.map((value)=>{
                    if(value.completed) arItemsL2.push(value)
                    else arItemsL1.push(value)
                })
                setItemsL1(arItemsL1)
                setItemsL2(arItemsL2)
            }).catch((error) => {
            })
        })()
    }, [])

    return [itemsL1,setItemsL1,itemsL2,setItemsL2]
}


const TestReact = () => {
    const [itemsL1,setItemsL1,itemsL2,setItemsL2] = useLoad();
    const input = useRef('')
    const [inputValue,setInputValue] = useState('')

    const switchGroup = (value,isFor) => {
        if(isFor=='fait'){
            let array = itemsL1.filter((element) => element!=value)
            setItemsL1(array)
            setItemsL2([...itemsL2,value])
        }
        else{
            let array = itemsL2.filter((element) => element!=value)
            setItemsL2(array)
            setItemsL1([...itemsL1,value])
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setItemsL1([...itemsL1,{
            "userId": 1,
            "id": 48,
            "title": input.current.value,
            "completed": false
        }])
        setInputValue('')
    }

    const handleChange = (e) => {
        setInputValue(e.target.value)
    }

    return (<>
        <a target="_blank" href="https://jsonplaceholder.typicode.com/todos?_limit=10">https://jsonplaceholder.typicode.com/todos?_limit=10</a>
        <div className="row">
            <div className="col-12 mt-5">
                <div className="row">
                    <div className="col-4 offset-2">
                        <div className="card">
                            <div className="card-header">
                                A faire
                            </div>
                            <div className="card-body">
                                <p>Nombre d'items: <strong>{itemsL1.length}</strong></p>
                                <ul className="list-group">
                                    {itemsL1.map((value,index)=>
                                        <a href="#" key={index} className="list-group-item list-group-item-danger list-group-item-action" onClick={() => switchGroup(value,'fait')}>{value.title}</a>
                                    )}
                                </ul>
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <input ref={input} value={inputValue} type="text" className="form-control mt-4" placeholder="Ajouter une tache..." onChange={(e) => handleChange(e)} />
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card">
                            <div className="card-header">
                                Fait
                            </div>
                            <div className="card-body">
                                <p>Nombre d'items: <strong>{itemsL2.length}</strong></p>
                                <ul className="list-group">
                                    {itemsL2.map((value,index)=>
                                        <a href="#" key={index} className="list-group-item list-group-item-success list-group-item-action" onClick={() => switchGroup(value,'afaire')}>{value.title}</a>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default TestReact;