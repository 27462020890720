
import 'bootstrap/dist/css/bootstrap.min.css';
import TestReact from './Pages/TestReact'

function App() {
  return (
    <TestReact />
  );
}

export default App;
